import { reactive, toRefs, nextTick, onMounted, inject, computed } from "vue";
import { ElCarousel, ElCarouselItem, ElEmpty } from "element-plus";
import PieEchart from "./PieEchart.vue";
export default {
  name: 'Left',
  components: {
    ElCarousel,
    ElCarouselItem,
    ElEmpty,
    PieEchart
  },
  props: {
    temperatureStatisticalVO: {
      type: Object,
      default: () => ({
        total: null,
        abnormalNum: null,
        normalNum: null,
        studentTotal: null
      })
    },
    homeIconList: {
      type: Array,
      default: []
    },
    data: {
      type: Object,
      default: {
        schoolNum: 0,
        cardNum: 0,
        onlineNum: 0,
        alarmNum: 0,
        attDeviceNum: 0,
        total: 0
      }
    }
  },
  setup(props) {
    const refData = reactive({
      refCarousel: null,
      card3Echarts: null,
      card2Echarts: null,
      card2List: null,
      left: 0,
      num: 0
    });
    const provideData = inject('rootProvide');
    const card2Width = computed(() => {
      var _refData$card2List;
      return (_refData$card2List = refData.card2List) === null || _refData$card2List === void 0 ? void 0 : _refData$card2List.clientWidth;
    });
    //初始化测温数据统计
    const initEcharts = () => {
      nextTick(() => {
        const data = [{
          name: '员工测温正常数',
          value: props.temperatureStatisticalVO.normalNum
        }, {
          name: '今日测温总数',
          value: props.temperatureStatisticalVO.total
        }];
        refData.card3Echarts.init(data);
        const data2 = [{
          name: '员工测温正常数',
          value: props.temperatureStatisticalVO.normalNum
        }, {
          name: '今日测温总数',
          value: props.temperatureStatisticalVO.total
        }];
        refData.card2Echarts.init(data2);
      });
    };
    //轮播单位考勤合格率
    const handleAnimation = () => {
      if (!provideData.attendanceStatisticalVO.length) return false;
      refData.num += 1;
      if (Math.abs(refData.left - card2Width.value) >= Math.abs(card2Width.value * provideData.attendanceStatisticalVO.length) + card2Width.value) {
        refData.left = 0;
        refData.num = 0;
      } else {
        const num = Math.abs(refData.left) + card2Width.value;
        let time = setInterval(() => {
          if (Math.abs(refData.left) < num) {
            refData.left -= 10;
          } else {
            refData.left = -(card2Width.value * refData.num);
            clearInterval(time);
          }
        }, 1);
      }
    };
    const handleSwitch = () => {
      setTimeout(() => {
        refData.refCarousel && refData.refCarousel.next();
      }, 1000);
      setTimeout(() => {
        handleAnimation();
        provideData.attendanceStatisticalVO.length > 1 && handleSwitch();
      }, 5000);
    };
    onMounted(() => {
      handleSwitch();
    });
    return {
      ...toRefs(refData),
      initEcharts,
      provideData,
      card2Width
    };
  }
};