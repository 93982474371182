import { defineComponent, reactive, toRefs, nextTick, markRaw } from "vue";
import * as echarts from "echarts";
export default defineComponent({
  name: "PieEchart",
  props: {
    data: {
      type: Array,
      default: () => []
    },
    color: {
      type: Array,
      default: ['#ff8518', '#0ad782']
    },
    radius: {
      type: Array,
      default: ["28%", "50%"]
    },
    center: {
      type: Array,
      default: ['center', '50%']
    },
    label: {
      type: Boolean,
      default: true
    },
    legend: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const refData = reactive({
      echartRefs: null,
      myChart: null,
      loading: false
    });
    nextTick(() => {
      setTimeout(() => {
        props.data.length && init(props.data);
      }, 1000);
    });
    //设置参数
    const handleOptions = data => {
      refData.myChart = markRaw(echarts.init(refData.echartRefs));
      let option = {
        legend: {
          show: props.legend,
          orient: 'vertical',
          right: 10,
          top: 'center',
          formatter: e => {
            return e;
          },
          textStyle: {
            marginBottom: 10,
            color: '#FFF'
          }
        },
        tooltip: {
          show: true,
          trigger: 'item',
          textStyle: {
            color: '#FFF',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontFamily: 'sans-serif',
            fontSize: 14,
            backgorund: "#FFF"
          }
        },
        color: props.color,
        series: [{
          name: '',
          type: 'pie',
          radius: props.radius,
          center: props.center,
          minAngle: 10,
          label: {
            normal: {
              show: props.label,
              formatter: e => {
                return `${e.data.name}\n${e.data.value}`;
              },
              borderWidth: 20,
              borderRadius: 4,
              textStyle: {
                color: "#9B9B9B"
              }
            }
          },
          labelLine: {
            normal: {
              lineStyle: {
                color: '#9B9B9B'
              },
              smooth: 0.2
            }
          },
          itemStyle: {
            borderWidth: 3,
            borderColor: '#0E265C'
          },
          data: data
        }]
      };
      option && refData.myChart.setOption(option);
      refData.loading = false;
      // 处理点击事件并且跳转到相应的百度搜索页面
    };
    //自适应大小
    const handleResizeChart = () => {
      refData.myChart && refData.myChart.resize();
    };
    //初始化
    const init = async data => {
      handleOptions(data);
      window.addEventListener("resize", handleResizeChart);
    };
    return {
      ...toRefs(refData),
      init
    };
  }
});