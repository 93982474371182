import { defineComponent, reactive, watchEffect } from 'vue';
import { GetGeo } from '@/api/apiFn';
import { toRefs } from '@vueuse/core';
export default defineComponent({
  name: 'AddressLabel',
  props: {
    point: {
      type: Object,
      default: null
    },
    ellipsis: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const refData = reactive({
      address: '',
      loading: false
    });
    async function getAddress(lon, lat) {
      const result = await GetGeo([{
        lon,
        lat,
        tag: 1
      }]);
      if (result && result.length > 0) {
        return result[0].address;
      }
      if (lon == 0) {
        return '未定位，经纬度为0';
      }
      return '';
    }
    watchEffect(async () => {
      try {
        refData.loading = true;
        refData.address = await getAddress(props.point.lon, props.point.lat);
      } catch (err) {
        console.error(err);
      } finally {
        refData.loading = false;
      }
    });
    return {
      ...toRefs(refData)
    };
  }
});