import { reactive, toRefs } from "vue";
import AddressLabel from "@/components/AddressLabel/";
export default {
  name: "homeAlarm",
  props: {
    params: {
      type: [Object, Number],
      default: {
        alarmName: "",
        mdtid: "",
        simid: "",
        holdname: "",
        className: "",
        objectname: "",
        lon: "",
        lat: ""
      }
    }
  },
  components: {
    AddressLabel
  },
  emits: ['handleHide'],
  setup(props, {
    emit
  }) {
    const refData = reactive({});
    const handleHide = () => {
      emit('handleHide');
    };
    return {
      ...toRefs(refData),
      handleHide
    };
  }
};